import React from "react";
import styled from "styled-components";
import { theme } from "../utilis/colors";

const FilterWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1.2rem;
  font-size: 1.2rem;
  justify-content: center;
  align-items: center;
`;

const FileterItem = styled.div`
  padding: 0.4rem 1.2rem;
  cursor: pointer;
  border-radius: 0.2rem;
  transition: 300ms;
  color: ${(p) => (p.active ? theme.action : theme.text)};
  :hover {
    background-color: rgba(255, 255, 255, 0.1);
    transition: 300ms;
  }
`;

const Filter = ({ active, changeCategory }) => {
  return (
    <FilterWrapper>
      <FileterItem
        active={active === "All"}
        onClick={() => changeCategory("All")}
      >
        All
      </FileterItem>
      <FileterItem
        active={active === "Photography"}
        onClick={() => changeCategory("Photography")}
      >
        Photography
      </FileterItem>
      <FileterItem
        active={active === "Programming"}
        onClick={() => changeCategory("Programming")}
      >
        Programming
      </FileterItem>
    </FilterWrapper>
  );
};
export default Filter;
