
import * as React from "react";
import styled from "styled-components";
import { ImgLink, ItemDescription, ItemLink, ItemLinkWrapper } from "./common";

const ItemProjectsSpace = styled.div`
  width: calc(50% - 1rem);
  display: flex;
  margin-bottom: 2rem;
  background-color: #305066;
  padding: 0.4rem 0.8rem;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  border-radius: 0.2rem;
	@media (max-width: 770px) {
    width: 100%;
  }
`;
const ItemImage = styled.img`
  width: 100%;
  display: flex;
  height: 100%;
`;

const ItemProjectsContent = styled.div`
  padding: 0 0.6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ItemTitle = styled.div`
  font-size: 1.4rem;
  text-align: center;
  width: 100%;
  padding: 0.8rem 0.4rem;
`;
const ItemSubtitle = styled.div`
  font-size: 0.8rem;
  text-align: center;
  width: 100%;
`;


const ItemBlog = ({ title, desc, coverImg, date, category, link }) => {
  return (
    <ItemProjectsSpace>
      <ItemSubtitle>
        {date} | {category}
      </ItemSubtitle>
      <ItemTitle>{title}</ItemTitle>
      <ImgLink to={link}>
        <ItemImage src={coverImg} />
      </ImgLink>
      <ItemProjectsContent>
        <ItemDescription>
          <div dangerouslySetInnerHTML={{ __html: desc }} />
        </ItemDescription>
        <ItemLinkWrapper>
          <ItemLink to={link}>Read more</ItemLink>
        </ItemLinkWrapper>
      </ItemProjectsContent>
    </ItemProjectsSpace>
  );
};

export default ItemBlog;
