import * as React from "react";
import { graphql } from "gatsby";
import Menu from "../components/header/Menu";
import Blog from "../components/blog/Blog";
import Layout from "../components/utilis/Layout";
import { Main } from "../components/utilis/common";
import Footer from "../components/utilis/Footer";

export const query = graphql`
  query {
    allWpPost(
      sort: { order: DESC, fields: [date] }
      filter: {
        categories: { nodes: { elemMatch: { parentDatabaseId: { eq: 185 } } } }
      }
    ) {
      edges {
        node {
          title
          id
          slug
          date(formatString: "DD-MM-YYYY")
          excerpt
          featuredImage {
            node {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`;

const BlogPage = ({ data }) => {
  const { allWpPost } = data;
  return (
    <Layout>
      <Main>
        <title>My Blog</title>
        <Menu alone />
        <Blog posts={allWpPost} />
        <Footer />
      </Main>
    </Layout>
  );
};

export default BlogPage;
