import React, { useState } from "react";
import Section from "../utilis/Section";

import { ItemsArea } from "../utilis/common";
import ItemBlog from "../utilis/ItemBlog";
import Filter from "./Filter";
import { theme } from "../utilis/colors";

const Blog = ({ posts }) => {
  const [category, updateCategory] = useState("All");

  const changeCategory = (cat) => {
    if (cat !== category) {
      updateCategory(cat);
    }
  };

  const filterdPosts = (cat) => {
    if (category !== "All") {
      return posts.edges.filter(
        (item) => item.node.categories.nodes[0].name === cat
      );
    }
    return posts.edges;
  };

  return (
    <Section bgColor={theme.mainBackground} textColor={theme.text} title="My Blog">
      <Filter
        active={category}
        changeCategory={changeCategory}
      />
      <ItemsArea>
        {filterdPosts(category).map((item) => (
          <ItemBlog
            title={item.node.title}
            desc={item.node.excerpt}
            key={item.node.id}
            coverImg={item.node.featuredImage.node.sourceUrl}
            date={item.node.date}
            category={item.node.categories.nodes[0].name}
						link={item.node.slug}
          />
        ))}
      </ItemsArea>
    </Section>
  );
};

export default Blog;
